import { Box, Button, Center, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import useOrders from "../../../../hooks/useOrders";
import useCart from "../../../../hooks/useCart";
import useUser from "../../../../hooks/useUser";
import MybalanceDashboard from "./mybalance-dashboard";
import { SlBasket } from "react-icons/sl";
import OrderContent from "./order-content";
import { MdClose } from "react-icons/md";
import Pagination from "../home/pagination";

const Orders = ({ show, onClose }) => {
  const {
    getCustomerWidgetFn,
    url,
    resetUrl,
    orders,
    pageStats,
    handlePageChange,
    getOrdersFn,
  } = useOrders(onClose);
  const { myBalanceCustomers } = useCart();
  const { customer } = useUser();

  const isCustomer = myBalanceCustomers?.data?.some(
    (item) =>
      item.phoneNumber === customer?.phone_number?.replace("234", "0") &&
      (item.userType === "BUYER" || item.userType === "CUSTOM")
  );

  const handleWidget = async () => {
    let data = {
      customerEmail: customer?.email_address,
    };
    await getCustomerWidgetFn.mutateAsync(data);
  };

  return (
    <>
      <MybalanceDashboard
        isOpen={url ? true : false}
        url={url}
        onClose={resetUrl}
      />
      <SideModal show={show} onClose={onClose}>
        <Box p="16px" display={"flex"} justifyContent={"space-between"}>
          {isCustomer ? (
            <Button
              borderRadius={"10px"}
              bg="#3F12C4"
              color="#fff"
              fontSize={"12px"}
              fontWeight={500}
              onClick={handleWidget}
              isLoading={getCustomerWidgetFn?.isLoading}
            >
              MyBalance Orders
            </Button>
          ) : (
            <Box> </Box>
          )}

          <Box cursor={"pointer"} onClick={onClose}>
            <MdClose />
          </Box>
        </Box>

        {getOrdersFn.isLoading ? (
          <Box h="70dvh" display={"grid"} placeItems={"center"}>
            <Spinner size={"md"} color='#3F12C4' />
          </Box>
        ) : (
          <Box>
            {orders?.orders?.length === 0 ? (
              <Box h="70dvh" display={"grid"} placeItems={"center"}>
                <Box>
                  <Center>
                    <Box
                      w="80px"
                      h="80px"
                      border={"1px solid #EEF0F4"}
                      display={"grid"}
                      placeItems={"center"}
                      borderRadius={"full"}
                    >
                      <SlBasket size={25} color="#D8DBEA" />
                    </Box>
                  </Center>
                  <Text
                    textAlign={"center"}
                    mt="16px"
                    color="#0F132499"
                    fontSize={"16px"}
                    fontWeight={400}
                  >
                    No Orders
                  </Text>
                </Box>
              </Box>
            ) : (
              <Box
                display={"flex"}
                flexDir={"column"}
                gap="16px"
                p="16px"
                pb="50px"
              >
                <Text fontWeight={600}>Orders</Text>
                {orders?.orders?.map((order) => (
                  <OrderContent order={order} key={order.id} />
                ))}

                <Box>
                  <Pagination
                    pageCount={pageStats?.totalPages}
                    handlePageChange={handlePageChange}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </SideModal>
    </>
  );
};

export default Orders;

const SideModal = ({ children, show, onClose }) => {
  return (
    <Box>
      <Box
        bg="black"
        opacity="0.5"
        position="fixed"
        top="0"
        w="100%"
        h="100dvh"
        onClick={onClose}
        right={show ? "0" : "-100%"}
        zIndex="90"
        transition="0.3s ease-in-out"
      />
      <Box
        position="fixed"
        top={{ base: "0", md: "20px" }}
        bottom={"20px"}
        w={{ base: "full", md: "400px" }}
        h={{ base: "100dvh", md: "95dvh" }}
        borderRadius={{ base: "0", md: "10px" }}
        right={{ base: show ? "0" : "-100%", md: show ? "20px" : "-100%" }}
        zIndex="100"
        bg="#fff"
        opacity={show ? "100%" : "0"}
        transition="0.3s ease-in-out"
        overflowY="scroll"
      >
        {children}
      </Box>
    </Box>
  );
};
