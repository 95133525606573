import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Text,
  Button,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useUser from "../../../../hooks/useUser";

const Login = ({ isOpen, onClose, switchModal }) => {
  const [isSecure, setIsSecure] = useState(true);
  const {  loginUserFn } =useUser(onClose)

  let initialValues = {
    email_address: "",
    password: "",
  };

  const handleSubmit = async (doc) => {
    await loginUserFn.mutateAsync(doc)
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", md: "lg" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Log In</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody py="24px">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange }) => (
              <Form>
                <Box display="flex" flexDir={"column"} gap="16px">
                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Email Address
                    </FormLabel>
                    <Input
                      focusBorderColor="#3F12C4"
                      name="email_address"
                      border={"1px solid #06021226"}
                      type="text"
                      value={values.email_address}
                      onChange={handleChange}
                      borderRadius={"5px"}
                      placeholder="Email Address"
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                        boxShadow: "#DBD0FB",
                        borderWidth: "0.5px",
                      }}
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        focusBorderColor="#3F12C4"
                        border={"1px solid #06021226"}
                        type={isSecure ? "password" : "text"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        borderRadius={"5px"}
                        placeholder="Password"
                        h="46px"
                        transition={"0.5s ease-in-out"}
                        _focus={{
                          outline: "1px solid #DBD0FB",
                        }}
                        _placeholder={{
                          color: "#E0E0E0",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      />
                      <InputRightElement h="46px">
                        {isSecure ? (
                          <AiOutlineEyeInvisible
                            size={20}
                            cursor="pointer"
                            onClick={() => setIsSecure(!isSecure)}
                          />
                        ) : (
                          <AiOutlineEye
                            size={20}
                            cursor="pointer"
                            onClick={() => setIsSecure(!isSecure)}
                          />
                        )}
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Button
                    bg="#3F12C4"
                    type="submit"
                    color="#fff"
                    fontSize={"12px"}
                    fontWeight={500}
                    mt="8px"
                    isLoading={loginUserFn.isLoading}
                  >
                    Log In
                  </Button>

                  <Text
                    fontSize={"12px"}
                    textAlign={"center"}
                    fontWeight={400}
                    color="#605D66"
                  >
                    Don't have an account?{" "}
                    <span
                      onClick={() => {
                        onClose();
                        switchModal()
                      }}
                      style={{
                        fontWeight: 500,
                        color: "#3F12C4",
                        cursor: "pointer",
                      }}
                    >
                      Sign Up
                    </span>
                  </Text>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Login;
