import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./styles.module.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Pagination = ({ pageCount, handlePageChange }) => {
  return (
    <ReactPaginate
      previousLabel={<FaAngleLeft size={14} />}
      nextLabel={<FaAngleRight size={14} />}
      breakLabel={"..."}
      onPageChange={handlePageChange}
      containerClassName={styles.pagination}
      pageCount={pageCount || 5}
      activeClassName={styles.active}
      previousClassName={styles.previous}
      nextClassName={styles.next}
    // forcePage={selected}
    />
  );
};

export default Pagination;
