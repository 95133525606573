import { put, takeEvery, call } from "@redux-saga/core/effects";
import {
  get_store,
  get_store_success,
  get_store_error,
  get_single_product,
  get_single_product_success,
  get_single_product_error,
  create_customer,
  create_customer_success,
  create_customer_error,
  login_customer,
  login_customer_success,
  login_customer_error,
  get_customer,
  get_customer_success,
  get_customer_error,
  update_customer,
  update_customer_success,
  update_customer_error,
  forgot_password,
  forgot_password_success,
  forgot_password_error,
  create_order,
  create_order_success,
  create_order_error,
  get_orders,
  get_orders_success,
  get_orders_error,
  get_single_order,
  get_single_order_success,
  get_single_order_error,
  get_shipping,
  get_shipping_success,
  get_shipping_error,
  get_taxes,
  get_taxes_success,
  get_taxes_error,
  start_payment,
  start_payment_success,
  start_payment_error,
} from "./reducer";
import api from "../../service/dataService";
import toast from "react-hot-toast";

function* getStoreSaga({ payload }) {
  const data = `christambassadorpharmacy`;
  try {
    const request = yield call(api.get, `/stores/fetch?store=${data}`);
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_store_success.type,
      payload: response,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_store_error.type,
    });
  }
}

function* getSingleProductSaga({ payload }) {
  try {
    const request = yield call(
      api.get,
      `/stores/product/?productId=${payload}`
    );
    const responseData = request.data;
    const response = responseData.data;
    yield put({
      type: get_single_product_success.type,
      payload: response,
    });
  } catch (err) {
    const { response } = err;
    console.log(response?.data?.error);
    yield put({
      type: get_single_product_error.type,
    });
  }
}

function* createCustomerSaga({ payload }) {
  try {
    const request = yield call(api.post, `/stores/customer`, payload);
    const responseData = request.data;
    if (responseData?.success === false) {
      toast.error(responseData.message);
    }
    yield put({
      type: create_customer_success.type,
    });

    localStorage.setItem("x-store-token", responseData.token);
    setTimeout(() => {
      if (window.location.pathname === "/cart") {
        window.location.reload();
      } else {
        window.location.href = "/";
      }
    }, 1000);
  } catch (error) {
    toast.error(error?.response?.data?.error);
    yield put({
      type: create_customer_error.type,
    });
  }
}

function* loginCustomerSaga({ payload }) {
  try {
    const request = yield call(api.post, `/stores/login`, payload);
    const responseData = request.data;
    console.log(responseData);
    if (responseData?.status === true) {
      toast.success(responseData.message);
      localStorage.setItem("x-store-token", responseData.token);
      setTimeout(() => {
        if (window.location.pathname === "/cart") {
          window.location.reload();
        } else {
          window.location.href = "/";
        }
      }, 1000);
    }
    yield put({
      type: login_customer_success.type,
    });
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error);
    yield put({
      type: login_customer_error.type,
    });
  }
}

function* getCustomerSaga() {
  try {
    const request = yield call(api.get, `/stores/customer`);
    const responseData = request.data;
    yield put({
      type: get_customer_success.type,
      payload: responseData.data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: get_customer_error.type,
    });
  }
}

function* updateCustomerSaga({ payload }) {
  try {
    const { close, data, id } = payload;
    const request = yield call(
      api.patch,
      `/stores/customer/update/${id}`,
      data
    );
    const responseData = request.data;
    toast.success(responseData.data);
    yield put({
      type: update_customer_success.type,
    });
    setTimeout(() => {
      close();
      window.location.reload();
    }, 1000);
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data);
    yield put({
      type: update_customer_error.type,
    });
  }
}

function* forgotPasswordSaga({ payload }) {
  try {
    const request = yield call(
      api.post,
      `/stores/customer/forgot-password`,
      payload
    );
    const response = request.data;
    console.log(response);
    yield put({
      type: forgot_password_success.type,
    });
  } catch (error) {
    toast.error(error?.response?.data?.error);
    yield put({
      type: forgot_password_error.type,
    });
  }
}

function* createOrderSagas({ payload }) {
  try {
    const request = yield call(
      api.post,
      `/stores/orders?user_id=${payload.id}&storeId=${payload.store}`,
      payload.data
    );
    const response = request.data;
    yield put({
      type: create_order_success.type,
    });
    toast.success(response.message);
    localStorage.removeItem("cart");
    setTimeout(() => {
      window.location.href = "/profile/orders";
    }, 1000);
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.error);
    yield put({
      type: create_order_error.type,
    });
  }
}

function* getOrdersSagas({ payload }) {
  try {
    const { user_id, customer_id, currentPage, storeId } = payload;
    const request = yield call(
      api.get,
      `/stores/orders?user_id=${user_id}&storeId=${storeId}&customer_id=${customer_id}&page=${currentPage}&perPage=10`
    );
    const response = request.data;
    const { data } = response;
    yield put({
      type: get_orders_success.type,
      payload: data,
    });
  } catch (err) {
    toast.error(err?.response?.data?.error);
    yield put({
      type: get_orders_error.type,
    });
  }
}

function* getSingleOrderSagas({ payload }) {
  try {
    const { id, user_id, customer_id, storeId } = payload;
    const request = yield call(
      api.get,
      `/stores/orders/${id}?user_id=${user_id}&storeId=${storeId}&customer_id=${customer_id}`
    );
    const response = request.data;
    yield put({
      type: get_single_order_success.type,
      payload: response.data,
    });
  } catch (err) {
    toast.error(err?.response?.data?.error);
    yield put({
      type: get_single_order_error.type,
    });
  }
}

function* getShippingSaga({ payload }) {
  try {
    const request = yield call(api.get, `/shipping/store/${payload}`);
    const response = request.data;
    yield put({
      type: get_shipping_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_shipping_error.type,
    });
  }
}

function* getTaxesSaga({ payload }) {
  try {
    const request = yield call(api.get, `/tax/store/${payload}`);
    const response = request.data;
    yield put({
      type: get_taxes_success.type,
      payload: response,
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: get_taxes_error.type,
    });
  }
}

function* startButtonPayment({ payload }) {
  try {
    const { doc } = payload;
    const request = yield call(api.post, `/payment-option/initiate`, doc);
    const response = request.data;
    if (response?.data.success) {
      window.open(response?.data?.data, '_blank')
      // yield call(createOrderSagas, { payload });
    }
    yield put({
      type: start_payment_success.type,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: start_payment_error.type,
    });
  }
}

function* storeSagas() {
  yield takeEvery(get_store.type, getStoreSaga);
  yield takeEvery(get_single_product.type, getSingleProductSaga);
  yield takeEvery(create_customer.type, createCustomerSaga);
  yield takeEvery(login_customer.type, loginCustomerSaga);
  yield takeEvery(get_customer.type, getCustomerSaga);
  yield takeEvery(update_customer.type, updateCustomerSaga);
  yield takeEvery(forgot_password.type, forgotPasswordSaga);
  yield takeEvery(create_order.type, createOrderSagas);
  yield takeEvery(get_orders.type, getOrdersSagas);
  yield takeEvery(get_single_order.type, getSingleOrderSagas);
  yield takeEvery(get_shipping.type, getShippingSaga);
  yield takeEvery(get_taxes.type, getTaxesSaga);
  yield takeEvery(start_payment.type, startButtonPayment);
}

export default storeSagas;
