import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import ShippingBlock from "../../blocks/cart/shipping";
import commaNumber from "comma-number";
import paystack from "../../../../assets/paystack.png";
import startbuttonlogo from "../../../../assets/startbutton.png";
import mybalancelogo from "../../../../assets/mybalance.png";

const methods = [
  {
    name: "paystack",
    image: paystack,
    countries: ["Nigeria"],
  },
  {
    name: "startbutton",
    image: startbuttonlogo,
    countries: ["Kenya", "Ghana", "South Africa"],
  },
  {
    name: "mybalance",
    image: mybalancelogo,
    countries: ["Nigeria"],
  },
];

const SummaryCheckout = ({
  shipping,
  shippingMethod,
  selectShippingMethod,
  tax,
  paymentMethod,
  handlePaymentMethod,
  subTotal,
  total,
  cart,
  handleCheckout,
  address,
  isAddress,
  token,
  handleSetAddress,
  isCreatingCustomer,
  isPayingBalance,
  shippingStates
}) => {

  return (
    <Box boxShadow={"sm"} borderRadius={"1px"}>
      {/* order summary */}
      <Box p="16px">
        <Text fontWeight={500}>Order Summary</Text>
        <Box display={"flex"} flexDir={"column"} gap="16px" mt="16px">
          {cart.map((cart) => (
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text color={"#605D66B2"}>
                x{cart.quantity} {cart.name}
              </Text>

              <Text>{commaNumber(cart?.price)}</Text>
            </Flex>
          ))}

          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text>Total</Text>
            <Text fontWeight={700}>{commaNumber(subTotal)}</Text>
          </Flex>
        </Box>
      </Box>

      {/* delivery */}
      {shipping?.length > 0 && (
        <Box p="16px" borderTop={"0.5px solid #D9D8DA"}>
          {shippingStates.includes(address?.state) ? <>
            <Text fontWeight={500} mb="16px">
              Delivery Method
            </Text>
            <Box display={"flex"} flexWrap={"wrap"} gap="16px">
              {shipping?.map((shipping) => (
                <ShippingBlock
                  key={shipping.id}
                  shipping={shipping}
                  selectShippingMethod={() => selectShippingMethod(shipping)}
                  isSelected={shippingMethod.id === shipping.id}
                />
              ))}
            </Box>
          </> : <Text color='red' fontSize={'12px'}>We don't deliver to your region yet</Text>}

        </Box>
      )}

      {/* delivery address */}
      {token && (
        <Box p="16px" borderTop={"0.5px solid #D9D8DA"}>
          <Text fontWeight={500} mb="16px">
            Delivery Address
          </Text>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={isAddress ? "none" : "block"} fontSize={"12px"}>
              {address?.address}
              <Text>
                {address?.city} {address?.state}, {address?.country}
              </Text>
            </Box>

            <Box
              cursor={"pointer"}
              onClick={handleSetAddress}
              color="#3F12C4"
              fontSize={"12px"}
              textDecor={"underline"}
            >
              {isAddress ? "Add" : "Update"}
            </Box>
          </Box>
        </Box>
      )}

      {/* summary */}
      <Box
        borderTop={
          tax?.length > 0 || shippingMethod?.id ? "0.5px solid #D9D8DA" : "none"
        }
        p="16px"
        display={"flex"}
        flexDir={"column"}
        gap="16px"
      >
        {tax?.length > 0 && (
          <Box display={"flex"} flexDir={"column"} gap="16px">
            {tax.map((tax) => (
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                key={tax.id}
              >
                <Text color="#605D66B2" fontWeight={500} fontSize={"14px"}>
                  {tax.taxName}
                </Text>
                <Text color={"#060212"} fontWeight={600} fontSize={"16px"}>
                  {commaNumber(tax.taxAmount)}
                </Text>
              </Flex>
            ))}
          </Box>
        )}
        {shippingMethod?.id && (
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text color="#605D66B2" fontWeight={500} fontSize={"14px"}>
              Delivery
            </Text>
            <Text color={"#060212"} fontWeight={600} fontSize={"16px"}>
              {commaNumber(shippingMethod.price)}
            </Text>
          </Flex>
        )}
      </Box>

      {/* order total */}
      <Box p="16px" borderTop={"0.5px solid #D9D8DA"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Text color="#060212" fontWeight={500} fontSize={"16px"}>
            Order Total
          </Text>
          <Text color={"#060212"} fontWeight={600} fontSize={"20px"}>
            {commaNumber(total)}
          </Text>
        </Flex>
      </Box>

      {/* payment method */}
      <Box p="16px" borderTop={"0.5px solid #D9D8DA"}>
        <Text fontWeight={500} mb="16px">
          Payment Method
        </Text>
        <Box display={"flex"} flexWrap={"wrap"} gap="16px">
          {methods
            .filter((item) => item.countries.includes(address?.country))
            .map((method, idx) => (
              <Box
                key={idx}
                flex={1}
                display={"grid"}
                placeItems={"center"}
                onClick={() => handlePaymentMethod(method.name)}
                bg={paymentMethod === method.name ? "#DBD0FB" : "white"}
                borderRadius={"10px"}
                cursor={"pointer"}
                border={
                  paymentMethod === method.name
                    ? "none "
                    : "1px solid #605D66B2"
                }
                p="8px"
              >
                <Image src={method.image} h="30px" objectFit={"contain"} />
              </Box>
            ))}
        </Box>
      </Box>

      {/* checkout button */}
      <Box p="16px" mt="24px">
        <Button
          bg="#3F12C4"
          zIndex={'1'}
          borderRadius={"10px"}
          _hover={{ bg: "#3F12C4" }}
          isLoading={isCreatingCustomer || isPayingBalance}
          color="#fff"
          onClick={handleCheckout}
          isDisabled={!shippingStates.includes(address?.state)}
          fontSize={"12px"}
          fontWeight={500}
          w="full"
        >
          Checkout
        </Button>
      </Box>
    </Box>
  );
};

export default SummaryCheckout;
