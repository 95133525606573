import CartII from "../cart/cart-ii";
import HomeII from "../home/home-ii";
import Product from "../product";

export const routes = [
  {
    path: "/home",
    element: <HomeII />,
  },
  {
    path: `/cart`,
    element: <CartII />,
  },
  {
    path: `/product/:id`,
    element: <Product/>
  }
];
