import { Box, Image, Text } from "@chakra-ui/react";
import commaNumber from "comma-number";
import React, { useEffect, useState } from "react";
import { checkImage } from "../../../../utils/helpers";

const ProductCard = ({ product }) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const validateImage = async () => {
      if (product?.images && product?.images[0]) {
        const isValid = await checkImage(product?.images[0]);
        setIsValid(isValid);
      } else {
        setIsValid(false);
      }
    };

    validateImage();
  }, [product]);
  return (
    <Box boxShadow={"sm"} borderRadius={"6px"}>
      {product?.images === null || product?.images?.length === 0 || !isValid ? (
        <Box
          h="250px"
          w={{ base: "full", md: "full" }}
          display={"grid"}
          placeItems={"center"}
          bg="#F9F9F9"
          borderTopRadius={"6px"}
          color='#605D66'
        >
          No Image
        </Box>
      ) : (
        <Image
          src={product?.images[0]}
          alt={product?.name}
          height={"250px"}
          w="full"
          objectFit={"contain"}
          borderTopRadius={"6px"}
        />
      )}
      <Box p="16px">
        <Text textTransform={"capitalize"} fontSize={"14px"} fontWeight={400}>
          {product?.name}
        </Text>
        <Text fontSize={"14px"} fontWeight={600} mt="4px">
          {commaNumber(product?.price)}
        </Text>
      </Box>
    </Box>
  );
};

export default ProductCard;
