import {
  Box,
  Button,
  Container,
  IconButton,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useProduct from "../../../hooks/useProduct";
import commaNumber from "comma-number";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { checkImage } from "../../../utils/helpers";

const Product = () => {
  const params = useParams();
  const [isValid, setIsValid] = useState(true);
  const {
    product,
    handleQuantity,
    quantity,
    addItemToCart,
    step,
    loading,
    handleStep,
  } = useProduct(params.id);

  useEffect(() => {
    const validateImage = async () => {
      if (product?.images && product?.images[step]) {
        const isValid = await checkImage(product?.images[step]);
        setIsValid(isValid);
      } else {
        setIsValid(false);
      }
    };

    validateImage();
  }, [product, step]);

  return (
    <Box>
      {loading ? (
        <Box h="100dvh" display={"grid"} placeItems={"center"}>
          <Spinner size={"xl"} color='#3F12C4' />
        </Box>
      ) : (
        <Container maxW="container.lg" py="150px" maxHeight={"100dvh"}>
          <Box
            display={"flex"}
            alignItems={{ base: "none", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            gap={"24px"}
          >
            <Box flex={1}>
              {product?.images == null ||
              product?.images?.length === 0 ||
              !isValid ? (
                <Box
                  h="400px"
                  w="full"
                  display={"grid"}
                  placeItems={"center"}
                  bg="#F9F9F9"
                >
                  {" "}
                  No Image{" "}
                </Box>
              ) : (
                <Image
                  src={product?.images[step]}
                  height={"auto"}
                  width={"full"}
                  alt={product?.name}
                  transition={"0.5s ease-in-out"}
                />
              )}

              {product?.images?.length > 1 && (
                <Box display={"flex"} justifyContent={"center"} mt="32px">
                  <Box display={"flex"} alignItems={"center"} gap="24px">
                    <IconButton
                      icon={<FaAngleLeft />}
                      onClick={() => handleStep("prev")}
                      bg="#F9F9F9"
                      w="40px"
                      h="40px"
                      borderRadius={"full"}
                      border="0.5px solid #E5E4E2"
                      color="#060212CC"
                    />

                    <IconButton
                      icon={<FaAngleRight />}
                      onClick={() => handleStep("next")}
                      bg="#F9F9F9"
                      w="40px"
                      h="40px"
                      borderRadius={"full"}
                      border="0.5px solid #E5E4E2"
                      color="#060212CC"
                    />
                  </Box>
                </Box>
              )}
            </Box>

            <Box flex={1}>
              <Text
                fontSize={"30px"}
                fontWeight={"600"}
                textTransform={"capitalize"}
              >
                {product?.name}
              </Text>
              <Text>
                {product?.currency || "NGN"}
                {commaNumber(product?.price)}
              </Text>
              {product?.quantity <= 10 && <Text fontSize={'12px'} color='red' my='4px'>{product?.quantity} units left</Text>}

              <Text color="#605D66" mt="16px">
                {product?.description}
              </Text>

              <Box display={"flex"} alignItems={"center"} gap="24px" my="40px">
                <Box display={"flex"} gap="24px" alignItems={"center"}>
                  <Box
                    onClick={() => {
                      if (quantity === 1) {
                        return;
                      }
                      handleQuantity("decrement");
                    }}
                    w="40px"
                    h="40px"
                    borderRadius={"full"}
                    cursor={"pointer"}
                    display={"grid"}
                    placeItems={"center"}
                    border="1px solid #605D66B2"
                    fontSize={"20px"}
                    color="#605D66B2"
                  >
                    -
                  </Box>
                  <Box>{quantity}</Box>
                  <Box
                    onClick={() => {
                      if (quantity === 10) {
                        return;
                      }
                      handleQuantity("increment");
                    }}
                    w="40px"
                    h="40px"
                    borderRadius={"full"}
                    cursor={"pointer"}
                    display={"grid"}
                    placeItems={"center"}
                    border="1px solid #605D66B2"
                    fontSize={"20px"}
                    color="#605D66B2"
                  >
                    +
                  </Box>
                </Box>

                <Button
                  bg="#3F12C4"
                  borderRadius={"full"}
                  color="#fff"
                  onClick={() => addItemToCart(product)}
                >
                  Add to Cart
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default Product;
