import { Box, Image, Text } from "@chakra-ui/react";
import React, { useState, useEffect} from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { checkImage } from "../../../../utils/helpers";

const CartContent = ({ cart, updateQuantity, removeItemFromCart }) => {
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        const validateImage = async () => {
          if (cart?.images && cart?.images[0]) {
            const isValid = await checkImage(cart?.images[0]);
            setIsValid(isValid);
          } else {
            setIsValid(false);
          }
        };
    
        validateImage();
      }, [cart]);
  return (
    <Box
      shadow={"sm"}
      borderRadius={"10px"}
      p="16px"
      display={"flex"}
      flexDir={{base: 'column', md: 'row'}}
      gap="24px"
    >
      {cart?.images === null || cart?.images?.length === 0 || !isValid ? (
        <Box
          h="150px"
          w={{base: 'full', md: "150px"}}
          display={"grid"}
          placeItems={"center"}
          bg="#F9F9F9"
        >
          {" "}
          No Image{" "}
        </Box>
      ) : (
        <Box w="150px" h="150px" borderRadius={"8px"}>
          <Image
            src={cart?.images[0]}
            alt={cart?.name}
            height={"150px"}
            objectFit={"cover"}
          />
        </Box>
      )}

      <Box flex={1} display={"flex"} justifyContent={"space-between"}>
        <Box flex={1}>
          <Text fontSize={"20px"} fontWeight={600} textTransform={"capitalize"}>
            {cart?.name}
          </Text>
          <Text
            fontSize={"16px"}
            color="#605D66"
            fontWeight={400}
            textTransform={"capitalize"}
          >
            {cart?.description}
          </Text>

          <Box display={"flex"} gap="24px" alignItems={"center"} mt="40px">
            <Box
              w="40px"
              h="40px"
              onClick={() => {
                if (cart.quantity === 1) {
                  return;
                }
                let data = {
                  type: "decrement",
                  id: cart?.id,
                };
                updateQuantity(data);
              }}
              borderRadius={"full"}
              cursor={"pointer"}
              display={"grid"}
              placeItems={"center"}
              border="1px solid #605D66B2"
              fontSize={"20px"}
              color="#605D66B2"
            >
              -
            </Box>
            <Box>{cart.quantity}</Box>
            <Box
              w="40px"
              h="40px"
              onClick={() => {
                if (cart.quantity === 10) {
                  return;
                }
                let data = {
                  type: "increment",
                  id: cart?.id,
                };
                updateQuantity(data);
              }}
              borderRadius={"full"}
              cursor={"pointer"}
              display={"grid"}
              placeItems={"center"}
              border="1px solid #605D66B2"
              fontSize={"20px"}
              color="#605D66B2"
            >
              +
            </Box>
          </Box>
        </Box>

        <Box cursor={"pointer"}>
          <HiOutlineTrash
            color="red"
            size={20}
            onClick={() => removeItemFromCart(cart.id)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CartContent;
