import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Input,
  Button,
} from "@chakra-ui/react";
import { City, Country, State } from "country-state-city";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ng_states } from "../../../../utils/enums";
import useUser from "../../../../hooks/useUser";

const allowedCountries = ["NG", "ZA", "KE", "GH"];

const UpdateProfile = ({ isOpen, onClose }) => {
  const countries = Country.getAllCountries();
  const [stateCode, setStateCode] = useState("NG");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { customer, updateUserFn } = useUser(onClose);

  useEffect(() => {
    if (stateCode === "NG") {
      setStates(ng_states);
    } else {
      const states = State.getStatesOfCountry(stateCode);
      setStates(states);
    }
  }, [stateCode]);

  let data = {
    address: customer?.address || "",
    state: customer?.state || "",
    city: customer?.city || "",
    country: customer?.country || "",
  };

  const handleCountry = (doc) => {
    if (doc.isoCode === "NG") {
      setStates(ng_states);
    } else {
      setStateCode(doc.isoCode);
    }
  };

  const handleState = (doc) => {
    if (doc?.lgas?.length > 0) {
      setCities(doc?.lgas);
    } else {
      const cities = City.getCitiesOfState(stateCode, doc.isoCode);
      setCities(cities);
    }
  };

  const handleSubmit = async (doc) => {
    let data = {
      ...doc,
      country: doc.country || "Nigeria",
    };
    await updateUserFn.mutateAsync({ data: data, id: customer.id });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "sm", md: "md" }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody py="32px">
          <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <Box display={"flex"} flexDir={"column"} gap="24px">
                  <FormControl isRequired>
                    <FormLabel fontSize="12px" fontWeight={500}>
                      Country
                    </FormLabel>
                    <Select
                      focusBorderColor="#3F12C4"
                      //   value={values.country}
                      border={"1px solid #06021226"}
                      borderRadius={"5px"}
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      onChange={(e) => {
                        const value = JSON.parse(e.target.value);
                        setFieldValue("country", value.name);
                        handleCountry(value);
                      }}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                    >
                      <option selected disabled value="">
                        Select Country
                      </option>
                      {countries
                        .filter((item) =>
                          allowedCountries.includes(item.isoCode)
                        )
                        .map((data) => (
                          <option
                            key={data.isoCode}
                            value={JSON.stringify(data)}
                            selected={
                              data.isoCode === stateCode ||
                              customer?.country === data.name
                            }
                          >
                            {data.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel fontSize="12px" fontWeight={500}>
                      State
                    </FormLabel>
                    <Select
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      borderRadius={"5px"}
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      onChange={(e) => {
                        const value = JSON.parse(e.target.value);
                        setFieldValue("state", value.name);
                        handleState(value);
                      }}
                    >
                      <option selected disabled value="">
                        Select State
                      </option>
                      {states.map((data) => (
                        <option
                          key={data.isoCode}
                          value={JSON.stringify(data)}
                          selected={values?.state === data.name}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontSize="12px" fontWeight={500}>
                      City
                    </FormLabel>
                    <Select
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      value={values.city}
                      borderRadius={"5px"}
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      onChange={(e) => {
                        setFieldValue("city", e.target.value);
                      }}
                    >
                      <option selected disabled value="">
                        Select City
                      </option>
                      {cities.map((data) => (
                        <option key={data.latitude} value={data.name || data}>
                          {data.name || data}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="12px" fontWeight="500">
                      Address
                    </FormLabel>
                    <Input
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      textTransform={"capitalize"}
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      borderRadius={"5px"}
                      placeholder="Address"
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <Button
                    mt="8px"
                    bg="#3F12C4"
                    type="submit"
                    color="#fff"
                    fontSize={"12px"}
                    fontWeight={500}
                    isLoading={updateUserFn.isLoading}
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateProfile;
