import api from "./dataService";

export const getStoreDetails = async (doc) => {
  // const data = `solatycoon`;
  try {
    const res = await api.get(`/stores/fetch?store=${doc}`);
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getStoreShipping = async (doc) => {
  try {
    const res = await api.get(`/shipping/store/${doc}`);
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getStoreTax = async (doc) => {
  try {
    const res = await api.get(`/tax/store/${doc}`);
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};
