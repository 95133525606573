import api from "./dataService";

export const getUser = async () => {
  try {
    const res = await api.get(`/stores/customer`);
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createUser = async (doc) => {
  try {
    const res = await api.post(`/stores/customer`, doc);
    return res?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUser = async (doc) => {
  try {
    const res = await api.patch(`/stores/customer/update/${doc.id}`, doc.data);
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const loginUser = async (doc) => {
  try {
    const res = await api.post(`/stores/login`, doc)
    return res?.data
  } catch (error) {
    throw new Error(error)
  }
}
