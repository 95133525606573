import toast from "react-hot-toast";

export const validateContent = (doc) => {
  const { customer_id, total_amount, items, shipping, currency } = doc;

  if (!customer_id || !total_amount || !currency) return false;

  if (!Array.isArray(items) || items.length !== 1) return false;

  if (Object.keys(shipping).length === 0) {
    toast.error("Shipping information is missing or invalid ");
    return false;
  }

  return true;
};


export const extractErrors = (response) => {
  console.log(response)
  if (response?.data?.details?.errors) {
    const firstKeyError = Object.keys(response.data.details.errors)[0]
    if (firstKeyError && response.data.details.errors[firstKeyError]?.length > 0) {
      return response.data.details.errors[firstKeyError][0]
    }
  }
  return response.data.error
}

export const checkImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;

    img.onload = () => resolve(true);   // Image loaded successfully
    img.onerror = () => resolve(false); // Image failed to load
  });
}