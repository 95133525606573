import React, { useState } from "react";
import useStore from "../../../hooks/useStore";
import { Container, Box, Grid, GridItem, Text, Input } from "@chakra-ui/react";
import ProductCard from "../blocks/home/product-card";
import { Link } from "react-router-dom";
import Pagination from "../blocks/home/pagination";

const HomeII = () => {
  const { products, store } = useStore();
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const limit = 12;

  const filteredProducts = products?.filter((item) =>
    item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const offset = page * limit;
  const currentItems = filteredProducts?.slice(offset, offset + limit);

  const handlePageChange = ({ selected }) => {
    setPage(selected);
  };

  return (
    <Box>
      <Container maxW={"container.xl"} py="140px">
        <Box mb="50px">
          <Box
            bg="#6A3CEE"
            borderRadius={"10px"}
            display={"grid"}
            placeItems={"center"}
            py="59px"
          >
            <Box>
              <Text
                fontWeight={700}
                color="#FFFFFF"
                fontSize={{ base: "30px", md: "56px" }}
                textAlign={"center"}
              >
                Welcome to{" "}
                <sapn style={{ textTransform: "capitalize" }}>
                  {store?.business_name}
                </sapn>
              </Text>

              <Text
                textAlign={"center"}
                color="#C3B1F8"
                fontSize={"18px"}
                fontWeight={400}
              >
                {store?.homePageTitle}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box mb="40px">
          <Input
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for product"
            w={{ base: "full", md: "400px" }}
            bg="#F9F9F9"
            border={"0.5px solid #E5E4E2"}
            focusBorderColor="#3F12C4"
            borderRadius={"24px"}
            transition={"0.5s ease-in-out"}
            color="#060212CC"
            _focus={{
              outline: "1px solid #DBD0FB",
            }}
            _placeholder={{
              color: "#060212CC",
              fontSize: "14px",
              fontWeight: "400",
            }}
          />
        </Box>
        <Grid
          templateColumns={{ base: "auto auto", md: "repeat(4, 1fr)" }}
          gap="24px"
        >
          {currentItems?.filter((item) => item.quantity !== 0)?.map((product) => (
            <GridItem key={product.id}>
              <Link to={`/product/${product.id}`}>
                <ProductCard product={product} />
              </Link>
            </GridItem>
          ))}
        </Grid>

        {currentItems?.length > 0 && (
          <Box mt='40px'>
            <Pagination
              pageCount={Math.ceil(filteredProducts.length / limit)}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default HomeII;
