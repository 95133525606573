import { useState } from "react";
import useStore from "./useStore";
import useUser from "./useUser";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCustomerWidget, getOrders } from "../service/order-service";

const useOrders = (onClose) => {
  const { user, store } = useStore();
  const { customer } = useUser();
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState("");
  const limit = 10;

  const getOrdersFn = useQuery({
    queryKey: ["getOrdersFn", user, store, customer, page, limit],
    queryFn: () =>
      getOrders({
        user_id: user?.id,
        customer_id: customer?.id,
        store: store?.id,
        page,
        limit,
      }),
    enabled: user?.id && customer?.id && store?.id ? true : false,
  });

  const getCustomerWidgetFn = useMutation({
    mutationKey: ["getCustomerWidgetFn"],
    mutationFn: (doc) => getCustomerWidget(doc),
    onSuccess: (res) => {
      setUrl(res?.data?.url);
      onClose();
    },
  });

  const resetUrl = () => {
    setUrl("");
  };

  const handlePageChange =(event) => {
    const { selected } = event
    setPage(selected + 1)
  }
  return {
    getOrdersFn,
    getCustomerWidgetFn,
    resetUrl,
    url,
    orders: getOrdersFn?.data,
    pageStats: getOrdersFn?.data?.pagination,
    handlePageChange
  };
};

export default useOrders;
