import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const initialState = {
  ui: {
    loading: false,
    loadingProduct: false,
    creating: false,
    loadingOrder: false,
    loadingOrders: false,
    loadingShipping: false,
    updating: false,
    creating_user: false,
    paying: false,
  },
  store: [],
  user: {},
  product: {},
  cart: [],
  customer: {},
  orders: [],
  single_order: {},
  merchant: {},
  shipping: [],
  user_shipping: JSON.parse(localStorage.getItem("user_shipping")) || {},
  tax: [],
  orders_pagination: {},
};

export const storeReducerSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    get_store: (state) => {
      state.ui.loading = true;
    },
    get_store_success: (state, { payload }) => {
      state.ui.loading = false;
      const { user, products, stores } = payload;
      state.store = products;
      state.user = user;
      state.merchant = stores[0];
    },
    get_store_error: (state) => {
      state.ui.loading = false;
    },
    get_single_product: (state) => {
      state.ui.loadingProduct = true;
    },
    get_single_product_success: (state, { payload }) => {
      state.ui.loadingProduct = false;
      state.product = payload;
    },
    get_single_product_error: (state) => {
      state.ui.loadingProduct = false;
    },

    get_cart: (state) => {
      const product = JSON.parse(localStorage.getItem("cart")) || [];
      state.cart = product;
    },

    add_to_cart: (state, { payload }) => {
      state.loadingProduct = true;
      const prev = JSON.parse(localStorage.getItem("cart")) || [];
      const existingProduct = prev.find(
        (product) =>
          product?.id === payload.id &&
          product.size === payload.size &&
          product.color === payload.color
      );

      if (existingProduct) {
        const updatedProducts = prev.map((product) =>
          product === existingProduct
            ? { ...product, quantity: product.quantity + payload.quantity }
            : product
        );
        localStorage.setItem("cart", JSON.stringify(updatedProducts));
        state.ui.loadingPartner = false;
        state.cart = updatedProducts;
        toast.success(`${payload.name} updated!!`);
      } else {
        localStorage.setItem("cart", JSON.stringify([...prev, payload]));
        toast.success(`${payload.name} added to cart!!`);
        state.ui.loadingProduct = false;
        state.cart.unshift(payload);
      }
    },
    remove_item_from_cart: (state, { payload }) => {
      const prev = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedData = prev.filter((item) => item.product_id !== payload);
      localStorage.setItem("cart", JSON.stringify(updatedData));
      state.cart = updatedData;
    },
    update_quantity: (state, { payload }) => {
      const prev = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedData = prev.map((item) => {
        if (item.id === payload.idx) {
          return { ...item, quantity: parseInt(payload.newQuantity) };
        }
        return item;
      });
      localStorage.setItem("cart", JSON.stringify(updatedData));
      state.cart = updatedData;
    },
    create_customer: (state) => {
      state.ui.creating_user = true;
    },
    create_customer_success: (state) => {
      state.ui.creating_user = false;
    },
    create_customer_error: (state) => {
      state.ui.creating_user = false;
    },
    login_customer: (state) => {
      state.ui.creating_user = true;
    },
    login_customer_success: (state) => {
      state.ui.creating_user = false;
    },
    login_customer_error: (state) => {
      state.ui.creating_user = false;
    },
    get_customer: (state) => {
      state.ui.loading = true;
    },
    get_customer_success: (state, { payload }) => {
      state.ui.loading = false;
      state.customer = payload;
    },
    get_customer_error: (state) => {
      state.ui.loading = false;
    },
    update_customer: (state) => {
      state.ui.updating = true;
    },
    update_customer_success: (state) => {
      state.ui.updating = false;
    },
    update_customer_error: (state) => {
      state.ui.updating = false;
    },
    forgot_password: (state) => {
      state.ui.creating = true;
    },
    forgot_password_success: (state) => {
      state.ui.creating = false;
    },
    forgot_password_error: (state) => {
      state.ui.creating = false;
    },
    reset_password: (state) => {
      state.ui.creating = true;
    },
    reset_password_success: (state) => {
      state.ui.creating = false;
    },
    reset_password_error: (state) => {
      state.ui.creating = false;
    },
    create_order: (state) => {
      state.ui.creating = true;
    },
    create_order_success: (state) => {
      state.ui.creating = false;
      state.cart = [];
    },
    create_order_error: (state) => {
      state.ui.creating = false;
    },
    get_orders: (state) => {
      state.ui.loadingOrders = true;
    },
    get_orders_success: (state, { payload }) => {
      state.ui.loadingOrders = false;
      state.orders = payload.orders;
      state.orders_pagination = payload.pagination;
    },
    get_orders_error: (state) => {
      state.ui.loadingOrders = false;
    },
    get_single_order: (state) => {
      state.ui.loadingOrder = true;
    },
    get_single_order_success: (state, { payload }) => {
      state.ui.loadingOrder = false;
      state.single_order = payload;
    },
    get_single_order_error: (state) => {
      state.ui.loadingOrder = false;
    },
    cancel_single_order: (state) => {
      state.single_order = {};
    },
    get_shipping: (state) => {
      state.ui.loadingShipping = true;
    },
    get_shipping_success: (state, { payload }) => {
      state.ui.loadingShipping = false;
      const { data } = payload;
      state.shipping = data;
    },
    get_shipping_error: (state) => {
      state.ui.loadingShipping = false;
    },
    set_user_shipping: (state, { payload }) => {
      state.user_shipping = payload;
      localStorage.setItem("user_shipping", JSON.stringify(payload));
    },
    get_taxes: (state) => {
      state.ui.loading = true;
    },
    get_taxes_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.tax = data;
    },
    get_taxes_error: (state) => {
      state.ui.loading = false;
    },
    start_payment: (state) => {
      state.ui.paying = true;
    },
    start_payment_success: (state) => {
      state.ui.paying = false;
    },
    start_payment_error: (state) => {
      state.ui.paying = false;
    },
  },
});

export const {
  get_store,
  get_store_success,
  get_store_error,
  get_single_product,
  get_single_product_success,
  get_single_product_error,
  get_cart,
  add_to_cart,
  remove_item_from_cart,
  update_quantity,
  create_customer,
  create_customer_success,
  create_customer_error,
  login_customer,
  login_customer_success,
  login_customer_error,
  get_customer,
  get_customer_success,
  get_customer_error,
  update_customer,
  update_customer_success,
  update_customer_error,
  forgot_password,
  forgot_password_success,
  forgot_password_error,
  reset_password,
  reset_password_success,
  reset_password_error,
  create_order,
  create_order_success,
  create_order_error,
  get_orders,
  get_orders_success,
  get_orders_error,
  get_single_order,
  get_single_order_success,
  get_single_order_error,
  cancel_single_order,
  get_shipping,
  get_shipping_success,
  get_shipping_error,
  set_user_shipping,
  get_taxes,
  get_taxes_success,
  get_taxes_error,
  start_payment,
  start_payment_success,
  start_payment_error
} = storeReducerSlice.actions;

export default storeReducerSlice.reducer;
