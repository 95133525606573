import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./routes/routes";
import Layout from "./layout";

const Template2 = () => {
  return (
    <BrowserRouter>
    <Layout>
    <Routes>
        {routes.map((route, idx) => (
          <Route exact path={route.path} key={idx} element={route.element} />
        ))}
        <Route path="/" element={<Navigate to="/home" />} />
      </Routes>
    </Layout>
     
    </BrowserRouter>
  );
};

export default Template2;
