import { Box, Container, Text } from '@chakra-ui/react'
import React from 'react'
import useStore from '../../../hooks/useStore'

const FooterII = () => {
    const { user } = useStore()

  return (
    <Box bg='#6A3CEE' position={'relative'} bottom={0} mt='100px'>
        <Container maxW={'container.xl'} >
        <Box py='50px'>
            <Text fontSize={'16px'} color={'#fff'} fontWeight={600} mb='4px'>Contact Us</Text>
            <Box display={'flex'} gap={'16px'} alignItems={'center'}>
            <Text color='#fff'>{user?.phone_number}</Text>

            </Box>
        </Box>

        <Box borderTop={'0.5px solid #C3B1F8'} py='16px'>
        <Text color='#fff'>Made with ❤️ from Retailloop</Text>

        </Box>

        </Container>
    </Box>
  )
}

export default FooterII