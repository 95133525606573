// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pagination__\\+6y\\+s {
    display: flex;
    width: 100%;
    list-style: none;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .styles_pagination__\\+6y\\+s li {
    margin: 0 5px;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
  }

  .styles_pagination__\\+6y\\+s .styles_active__EGd06 {
    background-color: #DBD0FB;
    color: #3F12C4;
    border-radius: 5px;
  }

  .styles_pagination__\\+6y\\+s .styles_previous__9eKvd {
    display: grid;
    place-items: center;
  }

  .styles_pagination__\\+6y\\+s .styles_next__z6IqO {
    display: grid;
    place-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/pages/template2/blocks/home/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,QAAQ;IACR,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".pagination {\n    display: flex;\n    width: 100%;\n    list-style: none;\n    gap: 8px;\n    justify-content: flex-end;\n    margin-top: 30px;\n  }\n  \n  .pagination li {\n    margin: 0 5px;\n    cursor: pointer;\n    padding: 10px 15px;\n    border-radius: 5px;\n    font-size: 16px;\n  }\n\n  .pagination .active {\n    background-color: #DBD0FB;\n    color: #3F12C4;\n    border-radius: 5px;\n  }\n\n  .pagination .previous {\n    display: grid;\n    place-items: center;\n  }\n\n  .pagination .next {\n    display: grid;\n    place-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `styles_pagination__+6y+s`,
	"active": `styles_active__EGd06`,
	"previous": `styles_previous__9eKvd`,
	"next": `styles_next__z6IqO`
};
export default ___CSS_LOADER_EXPORT___;
