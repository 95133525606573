import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createUser,
  getUser,
  loginUser,
  updateUser,
} from "../service/user-service";
import toast from "react-hot-toast";
import useStore from "./useStore";

const useUser = (close) => {
  const { store } = useStore();
  const userFn = useQuery({
    queryKey: ["userFn"],
    queryFn: () => getUser(),
  });

  const createUserFn = useMutation({
    mutationKey: ["createUserFn"],
    mutationFn: (doc) => createUser(doc),
    onSuccess: (res) => {
      toast.success("User Created");
      let data = {
        store: store?.business_name,
        token: res.token,
      };
      localStorage.setItem("store-token", JSON.stringify(data));
      userFn.refetch();
      close();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const loginUserFn = useMutation({
    mutationKey: ["loginUserFn"],
    mutationFn: (doc) => loginUser(doc),
    onSuccess: (res) => {
      let data = {
        store: store?.business_name,
        token: res.token,
      };
      localStorage.setItem("store-token", JSON.stringify(data));
      userFn.refetch();
      close();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const logout = () => {
    localStorage.removeItem("store-token");
    userFn.refetch();
    window.location.reload();
  };

  const updateUserFn = useMutation({
    mutationKey: ["updateUserFn"],
    mutationFn: (doc) => updateUser(doc),
    onSuccess: (res) => {
      toast.success(res);
      userFn.refetch();
      close();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return {
    userFn,
    createUserFn,
    isCreating: createUserFn.isLoading,
    customer: userFn?.data,
    updateUserFn,
    logout,
    loginUserFn,
  };
};

export default useUser;
