import { useQuery } from "@tanstack/react-query";
import {
  getStoreDetails,
  getStoreShipping,
} from "../service/store-details";

const useStore = () => {
  const url = window.location.href;
  const parts = new URL(url).hostname.split(".");
  const name = parts[0];

  const getStoreFn = useQuery({
    queryKey: ["getStoreFn"],
    queryFn: () => getStoreDetails(name),
    enabled: name ? true : false
  });

  let store = getStoreFn?.data?.stores[0] || {};

  const getShippingFn = useQuery({
    queryKey: ["getShippingFn", store],
    queryFn: () => getStoreShipping(store.id),
    enabled: store.id ? true : false,
  });


  return {
    user: getStoreFn?.data?.user || {},
    products: getStoreFn?.data?.products || [],
    store: store,
    loadingStore: getStoreFn.isLoading,
    shipping: getShippingFn?.data ? getShippingFn?.data[0]?.rates : [],
    shippingStates: getShippingFn?.data ? getShippingFn?.data[0]?.states : [],
    getShippingFn,
  };
};

export default useStore;
