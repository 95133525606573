import { Box } from "@chakra-ui/react";
import React from "react";
import NavbarII from "./navbar";
import FooterII from "./footer";

const Layout = ({ children }) => {
  return (
    <Box display={"flex"} flexDir={"column"}>
      <NavbarII />
      <Box overflowX={"hidden"}>
        {children}
      </Box>
      <FooterII/>
    </Box>
  );
};

export default Layout;
