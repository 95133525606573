import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import logo from "../src/assets/favicon.png";
import Template2 from "./pages/template2";
import useStore from "./hooks/useStore";
import { Box, Spinner } from "@chakra-ui/react";

const App = () => {
  const { user, store, loadingStore } = useStore();

  useEffect(() => {
    if (store?.business_name) {
      const userAuth = JSON.parse(localStorage.getItem("store-token"));
      if (userAuth?.store !== store?.business_name) {
        localStorage.removeItem("store-token");
      } else {
        return;
      }
    }
  }, [store]);

  useEffect(() => {
    const { business_name } = user;
    const storename =
      business_name?.charAt(0).toUpperCase() + business_name?.slice(1);
    document.title = storename || "Retailloop Store";
  }, [user]);

  return (
    <div>
      <Toaster />
      <Helmet>
        <link rel="icon" type="image/png" href={user?.brandPhoto || logo} />
        <link rel="apple-touch-icon" href={user?.brandPhoto || logo} />
        <meta name="title" content={store?.homePageTitle} />
        <meta name="og:title" content={store?.homePageTitle} />
        <meta name="description" content={store?.homePageDescription} />
        <title>{store.business_name}</title>
      </Helmet>
      {loadingStore ? (
        <Box h="100dvh" display={"grid"} placeItems={"center"}>
          <Spinner size={"xl"} color='#3F12C4' />
        </Box>
      ) : (
        <Box bg="#FFFFFF" maxHeight={"100dvh"}>
          <Template2 />
        </Box>
      )}
    </div>
  );
};

export default App;
