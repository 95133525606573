import {
  Avatar,
  Box,
  Container,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiShoppingCart } from "react-icons/ci";
import { Link } from "react-router-dom";
import Orders from "../blocks/orders";
import useStore from "../../../hooks/useStore";
import SignUpModal from "../blocks/auth/sign-up";
import Login from "../blocks/auth/login";
import useUser from "../../../hooks/useUser";
import UserProfile from "../blocks/profile/user-profile";
import { FaRegUser } from "react-icons/fa";

const NavbarII = () => {
  const [cart, setCart] = useState([]);
  const [show, setShow] = useState(false);
  const token = JSON.parse(localStorage.getItem("store-token"));
  const { user, store } = useStore();
  const [isVisible, setIsVisible] = useState(false);
  const [see, setSee] = useState(false);
  const { customer, logout } = useUser();
  const [showProfile, setShowProfile] = useState(false);


  useEffect(() => {
    const updateCart = () => {
      const storedCart =
        JSON.parse(localStorage.getItem(store?.business_name)) || [];
      setCart(storedCart);
    };

    updateCart(); // Load cart initially

    window.addEventListener("cartUpdated", updateCart); // Listen for updates

    return () => {
      window.removeEventListener("cartUpdated", updateCart); // Cleanup listener
    };
  }, [store?.business_name]);

  return (
    <Box>
      <Orders show={show} onClose={() => setShow(!show)} />
      <SignUpModal
        isOpen={isVisible}
        onClose={() => setIsVisible(!isVisible)}
      />
      <Login
        isOpen={see}
        onClose={() => setSee(!see)}
        switchModal={() => setIsVisible(true)}
      />
      <UserProfile
        isOpen={showProfile}
        onClose={() => setShowProfile(!showProfile)}
        customer={customer}
      />
      <Box
        position={"fixed"}
        top={0}
        w="full"
        boxShadow={"sm"}
        bg="white"
        zIndex={2}
      >
        <Container maxW="container.xl" py="24px">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <Link
                to="/"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Avatar
                  name={store?.business_name}
                  src={user?.brandPhoto}
                  boxShadow={"sm"}
                />
                <Text
                  textTransform={"capitalize"}
                  fontSize={"14px"}
                  fontWeight={600}
                >
                  {store?.business_name}
                </Text>
              </Link>
            </Box>

            <Box display={"flex"} gap={"24px"} alignItems={"center"}>
              <Menu placement="bottom">
                <MenuButton>
                  <Text display={{ base: "none", md: "block" }}>
                    My Account
                  </Text>
                  <Box display={{ base: "block", md: "none" }}>
                    <FaRegUser size={20} color="#14151A" />
                  </Box>
                </MenuButton>

                <MenuList
                  border="1px solid #DEE0E3"
                  boxShadow={"md"}
                  px="4px"
                  py="4px"
                  mt="10px"
                  mr={{ base: "20px", md: "0px" }}
                >
                  {token?.token && (
                    <MenuItem>
                      <Box display={"flex"} gap={"8px"} alignItems={"center"}>
                        <Avatar
                          name={
                            customer?.first_name + " " + customer?.last_name
                          }
                          w="40px"
                          h="40px"
                        />
                        <Box>
                          <Text
                            textTransform={"capitalize"}
                            fontSize={"14px"}
                            color="#14151A"
                            fontWeight={500}
                          >
                            {customer?.first_name} {customer?.last_name}
                          </Text>
                          <Text
                            fontSize={"12px"}
                            fontWeight={400}
                            color="#0D112666"
                          >
                            {customer?.email_address}
                          </Text>
                        </Box>
                      </Box>
                    </MenuItem>
                  )}
                  
                  <MenuDivider display={token?.token ? 'block' : 'none'} />
                  {token?.token && (
                    <MenuItem
                      onClick={() => setShow(!show)}
                      borderRadius={"6px"}
                      _hover={{
                        bg: "#DBD0FB",
                        color: "#3F12C4",
                        borderRight: "1px solid #3F12C4",
                      }}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      My Orders
                    </MenuItem>
                  )}
                  {token?.token && (
                    <MenuItem
                      borderRadius={"6px"}
                      _hover={{
                        bg: "#DBD0FB",
                        color: "#3F12C4",
                        borderRight: "1px solid #3F12C4",
                      }}
                      fontSize={"12px"}
                      fontWeight={500}
                      onClick={() => setShowProfile(!showProfile)}
                    >
                      My Profile
                    </MenuItem>
                  )}
                  {token?.token && (
                    <MenuItem
                      borderRadius={"6px"}
                      _hover={{
                        bg: "#DBD0FB",
                        color: "#3F12C4",
                        borderRight: "1px solid #3F12C4",
                      }}
                      fontSize={"12px"}
                      fontWeight={500}
                      onClick={logout}
                    >
                     Logout
                    </MenuItem>
                  )}
                  {!token?.token && (
                    <MenuItem
                      borderRadius={"6px"}
                      _hover={{
                        bg: "#DBD0FB",
                        color: "#3F12C4",
                        borderRight: "1px solid #3F12C4",
                      }}
                      fontSize={"12px"}
                      fontWeight={500}
                      onClick={() => setSee(!see)}
                    >
                      Login
                    </MenuItem>
                  )}
                  {!token?.token && (
                    <MenuItem
                      borderRadius={"6px"}
                      _hover={{
                        bg: "#DBD0FB",
                        color: "#3F12C4",
                        borderRight: "1px solid #3F12C4",
                      }}
                      fontSize={"12px"}
                      fontWeight={500}
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      Sign Up
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>

              <Link to={"/cart"}>
                <Box position={"relative"}>
                  {cart.length > 0 && (
                    <Box
                      w="15px"
                      h="15px"
                      position={"absolute"}
                      right={0}
                      top={0}
                      bg="#DBD0FB"
                      borderRadius={"full"}
                    ></Box>
                  )}

                  <Box
                    w="40px"
                    h="40px"
                    display={"grid"}
                    placeItems={"center"}
                    borderRadius={"full"}
                    border="1px solid #605D66B2"
                  >
                    <CiShoppingCart color="#605D66B2" size={20} />
                  </Box>
                </Box>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default NavbarII;
