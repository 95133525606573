import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  Input,
  ModalCloseButton,
  InputGroup,
  InputRightElement,
  Button,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styles from "./styles.module.css";
import * as Yup from "yup";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useUser from "../../../../hooks/useUser";
import useStore from "../../../../hooks/useStore";

const SignUpModal = ({ isOpen, onClose, switchModal }) => {
  const [isSecure, setIsSecure] = useState(true);
  const [length, setLength] = useState(false);
  const [caseCheck, setCase] = useState(false);
  const [character, setCharacter] = useState(false);
  const [isEmpty, setIsempty] = useState(true);
  const { createUserFn, isCreating } = useUser(onClose);
  const { user, store } = useStore();

  const handlePassword = (text) => {
    const specialCharReg = new RegExp('[!@#$%^&*(),.?": {} |<>]');
    const casingReg = new RegExp("(?=.*[a-z])(?=(.*[A-Z]))");
    setIsempty(!text.length > 0);
    setLength(text.length >= 8);
    setCase(casingReg.test(text));
    setCharacter(specialCharReg.test(text));
  };

  let initialValues = {
    first_name: "",
    last_name: "",
    email_address: "",
    password: "",
    phone_number: "",
  };

  const Schema = Yup.object().shape({
    email_address: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be at least  8 characters")
      .matches('[!@#$%^&*(),.?": {} |<>]', "No special character"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .min(13)
      .max(14),
  });

  const handleSubmit = async (doc) => {
    const formatted_number = doc.phone_number.replace(/\+/g, "");
    let data = {
      ...doc,
      user_id: user.id,
      phone_number: formatted_number,
      store_id: store.id,
    };
    await createUserFn.mutateAsync(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", md: "lg" }}
      isCentered
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Text>Sign Up</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody py="24px">
          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              errors,
              touched,
              handleBlur,
              setFieldTouched,
            }) => (
              <Form>
                <Box display={"flex"} flexDir={"column"} gap="16px">
                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      First Name
                    </FormLabel>
                    <Input
                      focusBorderColor="#3F12C4"
                      name="first_name"
                      border={"1px solid #06021226"}
                      type="text"
                      value={values.first_name}
                      onChange={handleChange}
                      textTransform={"capitalize"}
                      borderRadius={"5px"}
                      placeholder="First Name"
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Last Name
                    </FormLabel>
                    <Input
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      textTransform={"capitalize"}
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      borderRadius={"5px"}
                      placeholder="Last Name"
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Email Address
                    </FormLabel>
                    <Input
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      type="text"
                      name="email_address"
                      value={values.email_address}
                      onChange={handleChange}
                      borderRadius={"5px"}
                      placeholder="Email Address"
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Phone Number
                    </FormLabel>
                    <PhoneInput
                      className={styles.container}
                      defaultCountry="NG"
                      countries={["NG", "KE", "GH", "ZA"]}
                      name="phone"
                      onChange={(phone) => setFieldValue("phone_number", phone)}
                      onBlur={(event) => {
                        setFieldTouched("phone_number", true, false);
                        handleBlur("phone_number")(event);
                      }}
                      required
                    />

                    {errors.phone_number && touched.phone_number && (
                      <Text color="red" mb="5px" fontSize="12px" mt="3px">
                        {errors.phone_number}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                      Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        focusBorderColor="#3F12C4"
                        border={"1px solid #06021226"}
                        type={isSecure ? "password" : "text"}
                        name="password"
                        value={values.password}
                        onChange={(e) => {
                          setFieldValue("password", e.target.value);
                          handlePassword(e.target.value);
                        }}
                        borderRadius={"5px"}
                        placeholder="Password"
                        h="46px"
                        transition={"0.5s ease-in-out"}
                        _focus={{
                          outline: "1px solid #DBD0FB",
                        }}
                        _placeholder={{
                          color: "#E0E0E0",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      />
                      <InputRightElement h="46px">
                        {isSecure ? (
                          <AiOutlineEyeInvisible
                            size={20}
                            cursor="pointer"
                            onClick={() => setIsSecure(!isSecure)}
                          />
                        ) : (
                          <AiOutlineEye
                            size={20}
                            cursor="pointer"
                            onClick={() => setIsSecure(!isSecure)}
                          />
                        )}
                      </InputRightElement>
                    </InputGroup>

                    {errors.password && touched.password && (
                      <Text color="red" mb="5px" fontSize="12px" mt="3px">
                        {errors.password}
                      </Text>
                    )}

                    {!isEmpty && (
                      <Box display="flex" flexDir="column" gap="8px" mt="20px">
                        <Box display="flex" gap="8px" alignItems="center">
                          {length ? (
                            <AiOutlineCheckCircle color="#10AA69" />
                          ) : (
                            <AiOutlineCloseCircle color="red" />
                          )}
                          <Text
                            color={length ? "#10AA69" : "red"}
                            fontSize="12px"
                          >
                            Must be at least 8 characters.
                          </Text>
                        </Box>

                        <Box display="flex" gap="8px" alignItems="center">
                          {caseCheck ? (
                            <AiOutlineCheckCircle color="#10AA69" />
                          ) : (
                            <AiOutlineCloseCircle color="red" />
                          )}
                          <Text
                            whiteSpace="wrap"
                            fontSize="12px"
                            color={caseCheck ? "#10AA69" : "red"}
                          >
                            {" "}
                            Must have at least one uppercase and one lowercase
                            letter.
                          </Text>
                        </Box>

                        <Box display="flex" gap="8px" alignItems="center">
                          {character ? (
                            <AiOutlineCheckCircle color="#10AA69" />
                          ) : (
                            <AiOutlineCloseCircle color="red" />
                          )}
                          <Text
                            color={character ? "#10AA69" : "red"}
                            fontSize="12px"
                          >
                            {" "}
                            Must include special characters !@£$%&.
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </FormControl>

                  <Button
                    bg="#3F12C4"
                    type="submit"
                    color="#fff"
                    fontSize={"12px"}
                    fontWeight={500}
                    mt="8px"
                    isLoading={isCreating}
                  >
                    Sign Up
                  </Button>

                  <Text
                    fontSize={"12px"}
                    textAlign={"center"}
                    fontWeight={400}
                    color="#605D66"
                  >
                    Already have an account?{" "}
                    <span
                      onClick={() => {
                        onClose();
                        switchModal()
                      }}
                      style={{
                        fontWeight: 500,
                        color: "#3F12C4",
                        cursor: "pointer",
                      }}
                    >
                      Sign In
                    </span>
                  </Text>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignUpModal;
