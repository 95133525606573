import { useQuery } from "@tanstack/react-query";
import { getProduct } from "../service/product-service";
import { useEffect, useState } from "react";
import useStore from "./useStore";
import toast from "react-hot-toast";

const useProduct = (id) => {
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]);
  const { store } = useStore();
  const [step, setStep] = useState(0);

  useEffect(() => {
    const getCart = async () => {
      const cart = await localStorage.getItem(store?.business_name);
      const storedCart = JSON.parse(cart);
      setCart(storedCart || []);
    };

    getCart().catch((error) => {
      console.log("Failed to get cart", error);
    });

  }, [store?.business_name]);

  const getProductFn = useQuery({
    queryKey: ["getProductFn", id],
    queryFn: () => getProduct(id),
    enabled: id ? true : false,
  });

  const handleQuantity = (type) => {
    if (type === "increment") {
      setQuantity((prev) => prev + 1);
    } else {
      setQuantity((prev) => prev - 1);
    }
  };

  const addItemToCart = (doc) => {
    let product = {
      ...doc,
      quantity: quantity,
    };

    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);

      const updatedCart = existingProduct
        ? prevCart.map((item) =>
            item.id === product.id
              ? { ...item, quantity: item.quantity + product.quantity }
              : item
          )
        : [...prevCart, product];

      localStorage.setItem(store?.business_name, JSON.stringify(updatedCart));
      toast.success(existingProduct ? "Item updated" : "Item added");
      window.dispatchEvent(new Event("cartUpdated"));

      return updatedCart;
    });
  };

  const handleStep = (doc) => {
    if (doc === "next") {
      setStep((prev) => {
        if (prev === getProductFn?.data?.images?.length - 1) {
          return prev;
        }
        return prev + 1;
      });
    } else {
      setStep((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }
  };

  return {
    loading: getProductFn?.isLoading,
    product: getProductFn?.data || {},
    handleQuantity,
    quantity,
    addItemToCart,
    step,
    cart,
    handleStep,
  };
};

export default useProduct;
