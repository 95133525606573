import React from 'react'
import { Box, Spinner, Text, Center } from "@chakra-ui/react";

const Loader = ({ loading}) => {
  return (
    <Box
      position="fixed"
      w="100%"
      h="100vh"
      display="grid"
      placeItems="center"
      top="0"
      right={loading ? "0" : "-100%"}
      opacity={loading ? "100%" : "0"}
      transition={"0.3s ease-in-out"}
      zIndex="200"
      bg="rgba(0, 0, 0, 0.65)"
    >
      <Box>
        <Center>
          <Spinner color="#fff" size="xl" />
        </Center>
        <Text textAlign="center" fontSize="18px" color="#fff" mt="16px">
          Processing Payment...
        </Text>
      </Box>
    </Box>
  )
}

export default Loader