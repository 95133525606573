import {
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    Box,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Button,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { getAllISOCodes } from "iso-country-currency";
import React from "react";

const allowedCurrencies = ["KE", "GH", "ZA"];

const SelectCurrency = ({ isOpen, onClose, submitStartButton }) => {
    const currencies = getAllISOCodes();
    let initialValues = {
        currency: "",
    };

    const handleSubmit = (doc) => {
        submitStartButton(doc)
        onClose()
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={{ base: "sm", md: "md" }}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <ModalCloseButton />
                </ModalHeader>

                <ModalBody py="32px">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ values, handleChange }) => (
                            <Form>
                                <Box>
                                    <FormControl isRequired>
                                        <FormLabel color="#333333" fontSize="14px" fontWeight="500">
                                            Currency
                                        </FormLabel>
                                        <Select
                                            onChange={handleChange}
                                            focusBorderColor="#3F12C4"
                                            value={values.currency}
                                            name="currency"
                                            border={"1px solid #06021226"}
                                            h="46px"
                                            transition={"0.5s ease-in-out"}
                                            _focus={{
                                                outline: "1px solid #DBD0FB",
                                            }}
                                        >
                                            <option selected disabled value=''>Select Currency</option>
                                            {currencies
                                                ?.filter((item) => allowedCurrencies.includes(item.iso))
                                                ?.map((item) => (
                                                    <option key={item.iso} value={item.currency}>
                                                        {item.countryName} - {item.currency}
                                                    </option>
                                                ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        type="submit"
                                        w="full"
                                        mt="24px"
                                        bg="#3F12C4"
                                        color="#fff"
                                        fontSize={"12px"}
                                        fontWeight={500}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SelectCurrency;
