import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const MybalanceDashboard = ({ isOpen, onClose, url }) => {
  
  return (
    <Modal
      size={{ base: "lg", md: "4xl" }}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pb="50px">
          <iframe
            src={url}
            title="dashboard"
            frameBorder="0"
            seamless={true}
            width={"100%"}
            height={"100%"}
            style={{ height: 500 }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MybalanceDashboard;
