// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__9mnQW  {
    border: 1px solid #06021226;
    padding: 0px 12px;
    height: 46px;
    border-radius: 6px;
}

.styles_container__9mnQW input {
    height: 46px;
    border: none;
    background-color: transparent;
  }
  
  .styles_container__9mnQW input:focus {
    border: transparent;
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/pages/template2/blocks/auth/styles.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;IACnB,aAAa;EACf","sourcesContent":[".container  {\n    border: 1px solid #06021226;\n    padding: 0px 12px;\n    height: 46px;\n    border-radius: 6px;\n}\n\n.container input {\n    height: 46px;\n    border: none;\n    background-color: transparent;\n  }\n  \n  .container input:focus {\n    border: transparent;\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__9mnQW`
};
export default ___CSS_LOADER_EXPORT___;
