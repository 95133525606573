import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { TbTruckDelivery } from "react-icons/tb";

const ShippingBlock = ({
  shipping,
  selectShippingMethod,
  isSelected,
}) => {
  return (
    <Box
      py="8px"
      px="8px"
      display={"flex"}
      alignItems={"center"}
      gap="8px"
      bg={isSelected ? "#3F12C4" : "white"}
      borderRadius={"10px"}
      border={isSelected ? "none " : "1px solid #605D66B2"}
      onClick={selectShippingMethod}
      cursor={"pointer"}
    >
      <TbTruckDelivery color={isSelected ? '#fff' : ''} />

      <Text color={isSelected ? "#fff" : "#605D66B2"} fontSize={'14px'}>
        {shipping?.processingTime}
      </Text>
    </Box>
  );
};

export default ShippingBlock;
