import { Box, Collapse, Text } from "@chakra-ui/react";
import commaNumber from "comma-number";
import moment from "moment";
import React, { useState } from "react";

const OrderContent = ({ order }) => {
  const [show, setShow] = useState(false);
  return (
    <Box
      boxShadow={"sm"}
      p="16px"
      borderRadius={"6px"}
      border="0.5px solid #F7F7F7"
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Text fontSize={"14px"} fontWeight={600}>
            {order?.unique_id?.slice(0, 3)}
          </Text>
          <Text fontSize={"12px"} color="#0F132499">
            {moment(order.date_created).format("LL")}
          </Text>
        </Box>

        <Box>
          {order.status === "pending" ? (
            <Box
              borderRadius={"5px"}
              bg="#FEF4D1"
              py="4px"
              px="7px"
              fontSize={"12px"}
              fontWeight={500}
              color="#D1A713"
            >
              Pending
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>

      <Box mt="24px">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize={"14px"} fontWeight={500} color="#0F132499">
            Items
          </Text>

          <Box
            onClick={() => setShow(!show)}
            cursor={"pointer"}
            color="#3F12C4"
            fontSize={"14px"}
            fontWeight={400}
          >
            View
          </Box>
        </Box>
        <Box>
          <Collapse animateOpacity={true} in={show}>
            <Box display={"flex"} flexDir={"column"} gap="16px" my="16px">
              {order?.products?.map((product, index) => (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  bg={index % 2 === 0 ? "#F5F6F7" : ""}
                  p={2}
                  borderRadius={"5px"}
                >
                  <Box>
                    <Text fontSize={"14px"} color={"#14151A"} fontWeight={400}>
                      {product?.name}
                    </Text>
                  </Box>

                  <Text fontSize={"14px"} color="#0F132499" fontWeight={400}>
                    {product?.quantity}pcs
                  </Text>

                  <Text fontSize={"14px"} color={"#14151A"} fontWeight={400}>
                    {commaNumber(product?.price)}
                  </Text>
                </Box>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderContent;
