import api from "./dataService";

export const getProduct = async (doc) => {
  try {
    const res = await api.get(`/stores/product/?productId=${doc}`);
    return res?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};
