import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Text,
  Select,
  Button,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { Country, City, State } from "country-state-city";
import { Form, Formik } from "formik";
import { ng_states } from "../../../../utils/enums";
import useUser from "../../../../hooks/useUser";
import { IoMdClose } from "react-icons/io";
const allowedCountries = ["NG", "ZA", "KE", "GH"];

const UserProfile = ({ isOpen, onClose, customer }) => {
  const countries = Country.getAllCountries();
  const [stateCode, setStateCode] = useState("NG");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const {  updateUserFn } = useUser(onClose)

  useEffect(() => {
    if (stateCode === "NG") {
      setStates(ng_states);
    } else {
      const states = State.getStatesOfCountry(stateCode);
      setStates(states);
    }
  }, [stateCode]);


  let data = {
    address: customer?.address || "",
    state: customer?.state || "",
    city: customer?.city || "",
    country: customer?.country || "",
  };

  const handleCountry = (doc) => {
    if (doc.isoCode === "NG") {
      setStates(ng_states);
    } else {
      setStateCode(doc.isoCode);
    }
  };

  const handleState = (doc) => {
    if (doc?.lgas?.length > 0) {
      setCities(doc?.lgas);
    } else {
      const cities = City.getCitiesOfState(stateCode, doc.isoCode);
      setCities(cities);
    }
  };

  const handleSubmit = async (doc) => {
    let data = {
      ...doc,
      country: doc.country || "Nigeria",
    };
    await updateUserFn.mutateAsync({ data: data, id: customer.id });
  };

  return (
    <SideModal show={isOpen} onClose={onClose}>
      <Box display={"flex"} justifyContent={"flex-end"} p="24px">
        <Box w='20px' h='20px' bg='#0A0F290A' borderRadius={'full'} display={'grid'} placeItems={'center'} cursor={'pointer'} onClick={onClose}>
        <IoMdClose color='#6C6A70' size={12} />
        </Box>
      </Box>

      <Box display={"flex"} alignItems={"center"} gap={"8px"} p="24px">
        <Avatar name={customer?.first_name + " " + customer?.last_name} />
        <Box>
          <Text textTransform={"capitalize"} fontSize={"14px"} fontWeight={500}>
            {" "}
            {customer?.first_name} {customer?.last_name}
          </Text>
          <Text fontSize={"12px"} fontWeight={400}>
            {customer?.email_address}
          </Text>
        </Box>
      </Box>

      <Box px="24px" mt="40px">
        <Formik initialValues={data} onSubmit={handleSubmit}>
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Box display={"flex"} flexDir={"column"} gap="24px">
                <FormControl isRequired>
                  <FormLabel fontSize="12px" fontWeight={500}>
                    Country
                  </FormLabel>
                  <Select
                    focusBorderColor="#3F12C4"
                    border={"1px solid #06021226"}
                    name="country"
                    borderRadius={"5px"}
                    h="46px"
                    transition={"0.5s ease-in-out"}
                    onChange={(e) => {
                      const value = JSON.parse(e.target.value);
                      setFieldValue("country", value.name);
                      handleCountry(value);
                    }}
                    _focus={{
                      outline: "1px solid #DBD0FB",
                    }}
                  >
                    <option selected disabled value="">
                      Select Country
                    </option>
                    {countries
                      .filter((item) => allowedCountries.includes(item.isoCode))
                      .map((data) => (
                        <option
                          key={data.isoCode}
                          value={JSON.stringify(data)}
                          selected={
                            data.isoCode === stateCode ||
                            customer?.country === data.name
                          }
                        >
                          {data.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                    <FormLabel fontSize="12px" fontWeight={500}>
                      State
                    </FormLabel>
                    <Select
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      borderRadius={"5px"}
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      onChange={(e) => {
                        const value = JSON.parse(e.target.value);
                        setFieldValue("state", value.name);
                        handleState(value);
                      }}
                    >
                      <option selected disabled value="">
                        Select State
                      </option>
                      {states.map((data) => (
                        <option
                          key={data.isoCode}
                          value={JSON.stringify(data)}
                          selected={values?.state === data.name}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>


                  <FormControl isRequired>
                    <FormLabel fontSize="12px" fontWeight={500}>
                      City
                    </FormLabel>
                    <Select
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      value={values.city}
                      borderRadius={"5px"}
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      onChange={(e) => {
                        setFieldValue("city", e.target.value);
                      }}
                    >
                      <option selected disabled value="">
                        Select City
                      </option>
                      {cities.map((data) => (
                        <option key={data.latitude} value={data.name || data}>
                          {data.name || data}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel color="#333333" fontSize="12px" fontWeight="500">
                      Address
                    </FormLabel>
                    <Input
                      focusBorderColor="#3F12C4"
                      border={"1px solid #06021226"}
                      textTransform={"capitalize"}
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      borderRadius={"5px"}
                      placeholder="Address"
                      h="46px"
                      transition={"0.5s ease-in-out"}
                      _focus={{
                        outline: "1px solid #DBD0FB",
                      }}
                      _placeholder={{
                        color: "#E0E0E0",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    />
                  </FormControl>

                  <Button
                    mt="8px"
                    bg="#3F12C4"
                    type="submit"
                    color="#fff"
                    fontSize={"14px"}
                    fontWeight={500}
                    isLoading={updateUserFn.isLoading}
                  >
                    Save
                  </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </SideModal>
  );
};

export default UserProfile;

const SideModal = ({ children, show, onClose }) => {
  return (
    <Box>
      <Box
        bg="black"
        opacity="0.5"
        position="fixed"
        top="0"
        w="100%"
        h="100dvh"
        onClick={onClose}
        right={show ? "0" : "-100%"}
        zIndex="90"
        transition="0.3s ease-in-out"
      />
      <Box
        position="fixed"
        top={{ base: "0", md: "20px" }}
        w={{ base: "full", md: "400px" }}
        h={{ base: "100dvh", md: "95dvh" }}
        borderRadius={{ base: "0", md: "10px" }}
        right={{ base: show ? "0" : "-100%", md: show ? "20px" : "-100%" }}
        zIndex="100"
        bg="#fff"
        opacity={show ? "100%" : "0"}
        transition="0.3s ease-in-out"
        overflowY="scroll"
      >
        {children}
      </Box>
    </Box>
  );
};
